import React from 'react';
import './WelcomePage.css';
import quality from '../assets/quality.png'
import community from '../assets/communityyy.png'
import expert from '../assets/expertadvise.png'

const WelcomePage = () => {
    return (
        <div className="welcome-container">
            <div className="welcome-grid">
                <div className="welcome-box">
                    <a href="https://store.pachila.in/">
                        <div className="box-content">
                            <h2>Shop</h2>
                            <p>Explore our wide range of products</p>
                        </div>
                    </a>
                </div>
                <div className="welcome-box">
                    <a href="/stories">
                        <div className="box-content">
                            <h2>Stories</h2>
                            <p>Discover our journey and customer experiences</p>
                        </div>
                    </a>
                </div>
                <div className="welcome-box">
                    <a href="/live-well">
                        <div className="box-content">
                            <h2>Live Well</h2>
                            <p>Tips and insights for a healthier life</p>
                        </div>
                    </a>
                </div>
                <div className="welcome-box">
                    <a href="/exclusive">
                        <div className="box-content">
                            <h2>Exclusive</h2>
                            <p>Access special offers and members-only content</p>
                        </div>
                    </a>
                </div>
            </div>

            <div className="welcome-intro">
                <h2>About Us</h2>
                <p>
                    At Pachila, we are committed to offering high-quality products that enhance your well-being.
                    Our journey is inspired by our passion for health, and we are proud to share it with you.
                    Explore our products, learn from our stories, and live a better life with Pachila.
                </p>
            </div>

            <div className="welcome-features">
                <div className="feature-box">
                    <img src={quality} alt="Quality Products" />
                    <h3>Quality Products</h3>
                    <p>Carefully curated to meet your needs and enhance your lifestyle.</p>
                </div>
                <div className="feature-box">
                    <img src={expert} alt="Expert Advice" />
                    <h3>Expert Advice</h3>
                    <p>Get tips and guidance from health professionals and wellness experts.</p>
                </div>
                <div className="feature-box">
                    <img src={community} alt="Community Focused" />
                    <h3>Community Focused</h3>
                    <p>Join our community of like-minded individuals striving for better health.</p>
                </div>
            </div>
        </div>
    );
};

export default WelcomePage;
