import React from 'react';
import './LiveWell.css'; // Add custom styling for LiveWell page
import liveWellLogo from '../assets/live-well.png'; // Place the logo in the assets folder
import Header from '../components/Header';
import Footer from '../components/Footer';

const LiveWell = () => {
  return (
    <>
      <Header /> {/* Include Header component */}
      <div className="live-well-container">
        <header className="live-well-header">
          <img src={liveWellLogo} alt="Live Well Logo" className="live-well-logo" />
          <h1>Live Well: Embrace Nature, Embrace Health</h1>
          <p>Your gateway to holistic wellness through traditional healing and ecological solutions.</p>
        </header>

        <section className="about-live-well">
          <h2>About Us</h2>
          <p>Liv Well is your gateway to holistic wellness. We believe in the power of nature to heal and rejuvenate. Our expert practitioners offer personalized care, combining traditional healing methods like Ayurveda, Homeopathy, and Siddha with modern ecological principles. Experience the transformative power of Liv Well and embark on a journey towards a healthier, happier you.</p>
        </section>

        <section className="main-content lists">
          <h2>Living Well with Ecology-Based Solutions</h2>
          <h3>Embrace Nature, Embrace Health: Holistic Solutions for Lifestyle Diseases</h3>
          <p>Are you tired of conventional treatments that only offer temporary relief? At Live Well, we believe in a holistic approach to health that addresses the root causes of lifestyle diseases. Our ecology-based solutions are designed to help you achieve optimal well-being through harmony with nature.</p>

          <ul>
            <li>Personalized Consultations: Tailored plans for your health.</li>
            <li>Nourishing Diet Plans: Organic, locally sourced foods for healing.</li>
            <li>Holistic Therapies: Ayurveda, yoga, and meditation for balance.</li>
            <li>Lifestyle Modifications: Sustainable habits for daily life.</li>
          </ul>
        </section>

        <section className="consultations lists">
          <h3>Consultations</h3>
          <ul>
            <li><strong>General Consultation</strong> (Rs. 500): 30-minute consultation with a physician for a personalized plan.</li>
            <li><strong>Diabetics Consultation</strong> (Rs. 500): Comprehensive diabetes care, focusing on ecological well-being.</li>
            <li><strong>Women's Disorders</strong> (Rs. 500): Addressing PCOS, menstrual issues, and more with natural remedies.</li>
            <li><strong>Cancer Consultation</strong> (Rs. 500): Ecology-based care during and after cancer treatment.</li>
            <li><strong>Skin Diseases</strong> (Rs. 500): Solutions for allergies, autoimmune disorders, and more.</li>
          </ul>
          <a href="/online-consultation" className="book-now-button">BOOK NOW</a>
        </section>

        <footer className="live-well-footer">
          <p>Consultation Hours: 9:00 AM – 9:00 PM, 7 days a week</p>
          <p>For support: <a href="mailto:support@livewell.com">Click here</a></p>
        </footer>
      </div>
      <Footer /> {/* Include Footer component */}
    </>
  );
};

export default LiveWell;
