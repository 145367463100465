import React, { useState } from 'react';
import './Header.css';
import logo from '../assets/pachila.png';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header className="site-header">
            <div className="header-container">
                <img className='logo-img' src={logo} alt="Pachila Logo" />
                <nav className={`main-nav ${isOpen ? 'open' : ''}`}>
                    <ul>
                        <li><a href="/shop">Shop</a></li>
                        <li><a href="/about">About</a></li>
                        <li><a href="/contact">Contact</a></li>
                        <li className="mobile-appointment-button"><a href="/appointment">Book an Appointment</a></li>
                    </ul>
                </nav>
                <button className="appointment-button">Book an Appointment</button>
                <button className="hamburger" onClick={toggleMenu}>
                    {isOpen ? '✖' : '☰'}
                </button>
            </div>
        </header>
    );
};

export default Header;
