import React from 'react';
import './Stories.css'; // Custom styling for Stories page
import headerImage from '../assets/storyboard.jpg'; // Replace with your chosen image for storyboard windows
import Header from '../components/Header';
import Footer from '../components/Footer';

const Stories = () => {
  return (
    <>
      <Header /> {/* Include the Header */}
      <div className="stories-container">
        <header className="stories-header">
          <img src={headerImage} alt="Stories Header" className="stories-header-image" />
          <h1>Stories from Pachila</h1>
          <p>Explore inspiring stories, healing journeys, and insights from Pachila's community.</p>
        </header>

        <section className="stories-content">
          <h2>Suggested Stories</h2>
          <div className="story-thumbnails">
            <div className="thumbnail-wrapper">
              <a href="https://www.youtube.com/watch?v=_wVY9dedK_4" target="_blank" rel="noopener noreferrer">
                <img src={`https://img.youtube.com/vi/_wVY9dedK_4/hqdefault.jpg`} alt="Healing Journey" />
              </a>
              <p>Healing Journey</p>
            </div>
            <div className="thumbnail-wrapper">
              <a href="https://www.youtube.com/watch?v=wMEFf-8MRao" target="_blank" rel="noopener noreferrer">
                <img src={`https://img.youtube.com/vi/wMEFf-8MRao/hqdefault.jpg`} alt="Wellness & Harmony" />
              </a>
              <p>Wellness & Harmony</p>
            </div>
            <div className="thumbnail-wrapper">
              <a href="https://www.youtube.com/watch?v=e5y2GF4JCtc" target="_blank" rel="noopener noreferrer">
                <img src={`https://img.youtube.com/vi/e5y2GF4JCtc/hqdefault.jpg`} alt="Mind & Body Balance" />
              </a>
              <p>Mind & Body Balance</p>
            </div>
            <div className="thumbnail-wrapper">
              <a href="https://www.youtube.com/watch?v=sMRXlq4B4HY" target="_blank" rel="noopener noreferrer">
                <img src={`https://img.youtube.com/vi/sMRXlq4B4HY/hqdefault.jpg`} alt="Holistic Health Practices" />
              </a>
              <p>Holistic Health Practices</p>
            </div>
            <div className="thumbnail-wrapper">
              <a href="https://www.youtube.com/watch?v=lVxnr4bSecQ" target="_blank" rel="noopener noreferrer">
                <img src={`https://img.youtube.com/vi/lVxnr4bSecQ/hqdefault.jpg`} alt="Sustainable Living" />
              </a>
              <p>Sustainable Living</p>
            </div>
          </div>
        </section>

        <section className="social-media-links">
          <h2>Follow Us</h2>
          <ul>
            <li>
              <a href="https://www.facebook.com/pachila.in/" target="_blank" rel="noopener noreferrer">
                Facebook
              </a>
            </li>
            <li>
              <a href="https://www.patreon.com/pachila" target="_blank" rel="noopener noreferrer">
                Patreon
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/pachila.in/?hl=en" target="_blank" rel="noopener noreferrer">
                Instagram
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/@Pachila" target="_blank" rel="noopener noreferrer">
                YouTube
              </a>
            </li>
          </ul>
        </section>
      </div>
      <Footer /> {/* Include the Footer */}
    </>
  );
};

export default Stories;
